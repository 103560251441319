.tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;

  .tag {
    padding: 3px 13px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.normal {
      background-color: rgba(26, 38, 49, 0.1);
    }

    &.blue {
      background-color: #D7E8FC;
    }

    &.pill {
      background-color: #428BB5;
      border-radius: 100px;
      height: 20px;
    }
  }
}
