$primary-color: #263E58;
$secondary-color: #636c73;
$error-color: #e8324a;
$divider-color: #e9eaeb;
$hover-border-color: #1a263195;
$main-content-border-radius: 20px;
$main-content-background-color: #ffffff;
$selected-card-background-color: #EDF0F5;
$selected-card-border-color: rgba(124, 151, 180, 0.70);
$default-card-border-color: #DDE4F0;
$save-btn-border-color: #264B5B;
//TODO extract / add white, other commonly used colors
