@use '../../../config/styles/mixins.scss';

.sosContainer {
  @include mixins.flex-col(flex-start, center);
  width: 100%;
  gap: 1rem;
  flex: 1;
  max-width: 680px;
  margin: auto;
  padding: 20px 0;
  .title-container {
    width: 65%;
  }

  .sos-banner {
    width: 60%;
    margin-bottom: 3rem;
    max-width: 200px;
  }

  .sos-description {
    width: 90%;
    margin-top: 0.8rem;
    margin-bottom: 2rem;
  }

  .sos-action-block {
    @include mixins.flex-col(center, center);
    gap: 1rem;
    width: 90%;
    margin-top: 1rem;
  }

  .bottom-divider {
    width: 25vw;
    height: 2px;
    margin-top: 20px;
    background-color: #121212;
  }
}