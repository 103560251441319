@use '../../../config/styles/defaultStyle.scss' as default;

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  &.with-bd {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &.full-page-loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 500;
  }

  &.section-page-loader {
    position: absolute;
    z-index: 9;

  }

  .animated-content {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .meetup {
      -webkit-animation: spin 1s infinite linear;
      animation: spin 1s infinite linear;
      height: 10px;
      width: 10px;
    }
    .meetup div {
      height: 100%;
      position: absolute;
      width: 100%;
      -webkit-animation: meetup 1.25s infinite ease;
      animation: meetup 1.25s infinite ease;
      background-color: default.$primary-color;
      border-radius: 100%;
    }
    .meetup div:nth-child(1) {
      --rotation: 90;
    }
    .meetup div:nth-child(2) {
      --rotation: 180;
    }
    .meetup div:nth-child(3) {
      --rotation: 270;
    }
    .meetup div:nth-child(4) {
      --rotation: 360;
    }
  }
}

@-webkit-keyframes meetup {
  0%,
  100% {
    -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
  }
  50% {
    -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
  }
}
@keyframes meetup {
  0%,
  100% {
    -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
  }
  50% {
    -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
