.header-container {
  width: 100%;
  height: 80px;
  background-color: transparent;
  border-bottom: 1px solid #dde4f0;
  display: flex;
  align-items: center;
  .hamburgerMenuBtn {
    display: none;
  }
}
.navbar-container {
  width: 92%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  .navbar-left-items {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    .nav-item {
      text-decoration: none;
      width: fit-content;
      padding: 8px 12px;
      height: 40px;
      text-align: center;
      line-height: 25px;
      border-radius: 4px;
      &.active {
        background-color: #e1e7ec !important;
      }
    }
  }
  .profile-dropdown {
    // margin-right: 70px;
    border: none;
    @media (max-width: 768px) {
      margin-top: 15px;

    }
  }

  .userinfo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .header-profile-image-container {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

/* media queries */

@media (max-width: 1000px) {
  .header-container {
    .userinfo-container {
      margin-right: 10px;
      .header-profile-image-container {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .hamburgerMenuBtn {
      width: 100%;
      display: block;
      padding: 1rem;
    }
    .navbar-container {
      display: none;
      &.open {
        display: block;
        flex-direction: column;
        position: absolute;
        top: 80px;
        background: white;
        height: calc(100vh - 80px);
        z-index: 10;
        width: 80%;
        box-sizing: border-box;
      }
      .navbar-left-items {
        width: auto;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 18px;
        .nav-item {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}
