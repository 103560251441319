@use '@styles/mixins.scss' as default;

.reschedule-confirmation {
  width: clamp(500px, 500px, 35vw);
  @include default.flex-col(center, center);
  padding: 1.5rem;
  gap: 1rem;
  .success-icon {
    height: 106px;
    width: 122px;
  }
  .action-handler {
    width: 100%;
    .button-container {
      width: 100%;
    }
  }

}

@media (max-width: 1000px) {
  .success-icon {
    height: 50px;
    width: 50px;
  }
  .reschedule-confirmation {
    width: 100%;
  }
}