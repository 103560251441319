.onsite-card-layout {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #DDE4F0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  background: #FBFCFE;
  min-width: 270px;
  .event-infor-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    justify-content: flex-start;
    img{
      width: 72px;
      height: 72px;
    }
    .event-info {
      width: 75%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      .event-details {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4px;
        height: 50px;
      }
      .tag{
        padding: 3px 8px;
      }
    }
  }
  .onsite-card-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    height: 68px;
    .provider-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      .provider-avatar{
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }
  .onsite-card-footer{
    margin: auto;
    width: 100%;
  }
}

@media(max-width: 768px){
  .onsite-card-layout {
    .event-infor-container {
      flex-direction: column;
      .event-info {
        width: 100%;
      }
    }
  }
}
