.document-wrapper {
  width: 200px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 10px;
  &.inbound {
    background: #E9EAEB;
  }
  &.outbound {
    background: #3C566F;
  }
  .figure-wrapper {
    width: 100%;
    border-radius: 8px;
    margin: 0;
    cursor: pointer;
    &.inbound {
      background: #dbdee0;
    }
    &.outbound {
      background: #374f66;
    }
    .pdf-thumbnail {
      height: 112px;
      border-radius: 8px 8px 0 0;
      .fallback-pdf{
        height: 112px;
        width: 190px;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
      }
    }
    .doc-details {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
    }
   
  }
  .attached-msg{
    padding: 8px;
  }
}
