@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

body {
  margin: 0;
  box-sizing: border-box;
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body div > * {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e6edf2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8f9faa;
}

.react-tel-input {
  width: 100%;
  height: 44px;
  font-family: Poppins, sans-serif !important;

  input {
    border: 1px solid #d0d5dd;
    border-radius: 8px !important;
    font-family: Poppins, sans-serif !important;
    height: 100% !important;
    width: 100% !important;
  }
  .flag-dropdown,
  .selected-flag {
    border-radius: 8px 0 0 8px !important;
    background: #fff;
  }
  ul.country-list {
    height: 20rem;
  }
}
