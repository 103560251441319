.backdrop-container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  right: 0;

  &.dark {
    background-color: rgba($color: #000000, $alpha: 0.2);
  }

  &.glass {
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background-color: rgba($color: #fff, $alpha: 0.2);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }

    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background-color: rgba($color: #000000, $alpha: 0.2);
    }
  }
}
