.audio-message-player {
  width: 220px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  box-sizing: border-box;
  padding: .5rem;

  .left-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    img {
      height: 2rem;
      width: 2rem;
      cursor: pointer;
    }
  }

  .voice-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;

    .info-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;

      .wave-surfer {
        width: 100%;
      }

      img {
        height: 0.75rem;
        width: auto;
      }
    }
  }
}
