@use '../../../config/styles/defaultStyle.scss' as default;
@use '../../../config/styles/mixins.scss';


.checkbox-container {
  @include mixins.flex-row(flex-start, center);
  gap: 0.5rem;
  
  input[type='checkbox'] {
    height: 1rem;
    width: 1rem;
    accent-color: default.$primary-color;
    outline: none;
    cursor: pointer;
  }
}
