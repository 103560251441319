@use '../../../config/styles/defaultStyle.scss' as default;

.dropdown {
  position: relative;
  border: 1px solid #dde4f0;
  border-radius: 0.5rem;
  background-color: #FBFCFE;

  .action {
    box-sizing: border-box;
    padding: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    background-color: default.$main-content-background-color;
    cursor: pointer;
  }
}
