@use '../../../../../config/styles/defaultStyle.scss' as default;

.profile-image-container {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  border: 2px solid default.$primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }
}
