@use '../../../../config/styles/mixins.scss';


.helpline-container {
  @include mixins.flex-col(space-between, center);
  min-height: 75vh;
  padding: 20px;
  background-color: #fcfbf8;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex: 1;

  .helpline-header {
    width: 80%;
  }

  .helpline-items {
    @include mixins.flex-col(flex-start, center);
    width: 100%;
    gap: 1.5rem;
    margin: auto;
    .toll-free-info-container {
      display: flex;
      align-items: flex-start;
      text-align: left;
      gap: 4px;
      background: #f0eee6;
      padding: 12px;
      border-radius: 8px;
      img {
        width: 16px;
        padding-top: 3px;
      }
    }

    .helpline-item {
      @include mixins.flex-col(center, center);
      width: 100%;
      gap: .5rem;
    }
  }
}

@media(max-width: 768px) {
  .helpline-container {
    padding: 16px;
  }
}
