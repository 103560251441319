.coach-details-wrapper {
  width: 100%;
  .coach-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0;
    gap: 2rem;
    img {
      width: 144px;
      height: 144px;
      border-radius: 8px;
    }
    .coach-info {
      width: 87%;
      display: flex;
      flex-direction: column;
      gap: 32px;
      .coach-specialisation {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 50%;
      }
    }
  }
}

@media (max-width: 768px) {
  .coach-details-wrapper {
    .coach-details {
      flex-direction: column;
      gap: 10px;
      .coach-info {
        width: 100%;
        .coach-specialisation {
          width: 100%;
          span {
            &.typography {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
