.icon-text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  .with-background {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #5d5f7526;
    padding: 10px;
    filter: brightness(0) saturate(100%) invert(38%) sepia(13%) saturate(651%) hue-rotate(197deg) brightness(93%) contrast(90%);
  }
}