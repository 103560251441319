.date-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 72px;
  height: 72px;
  background-color: #f2f4f7;
  border-radius: 8px;
  .month {
    text-transform: uppercase;
    background: #ed5b6e;
    width: 100%;
    text-align: center;
    border-radius: 8px 8px 0 0;
    height: 25px;
    line-height: 25px;
  }
  .date {
    background: #f2f4f7;
    height: 47px;
    line-height: 47px;
  }
}
