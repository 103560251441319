@use '../../../../../config/styles/defaultStyle.scss' as default;

.chat-helpline-container {
  height: 75vh;
  width: 75vw;
  padding-bottom: 60px;
  background-color: #fffefb;

  .chat-helpline-header {
    padding: 1rem;
    margin-bottom: 2rem;
  }

  .chat-helpline-item {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 1.5rem;
    border-bottom: 0.5px solid #b7ac8d;
  }

  .chat-toll-free-info-container {
    background-color: #f0eee6;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}
