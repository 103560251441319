@use '../../../config/styles/mixins.scss';

.select-list-container {
  @include mixins.flex-row(flex-start, flex-start);
  flex-wrap: wrap;
  width: 100%;
  gap: 0.75rem;

  .select-list-item {
    @include mixins.flex-row(center, center);
    padding: 0.75rem 1rem;
    min-width: 91px;
    width: max-content;
    background: rgba(241, 243, 246, 0.65);
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;

    &.selected {
      background: #edf1f5;
      border: 1px solid rgba(124, 151, 180, 0.7);
      border-radius: 13px;
    }
  }
}
