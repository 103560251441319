.counseling-card-wrapper {
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 1rem;
  background: #fff;
  padding: 2rem;
  box-sizing: border-box;
  .content {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    img {
      width: 180px;
      height: 180px;
    }
    .right-wrapper {
      width: 85%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.5rem;
      .counseling-session {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 10px 0px;
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #d9d9d9;
    margin: 2rem 0;
  }
  .slots-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    .slots-date-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .slots-date-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .slots-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      flex-wrap: wrap;

      .slots {
        height: 43px;
        width: 91px;
        border: 1px solid #eaecf0;
        background: #f9fafb;
        border-radius: 10px;
        text-align: center;
        line-height: 43px;
        &.disabled {
          border: 1px solid #d0d5dd;
          background: #fff;
          opacity: 0.5;
        }
      }
    }
    
  }
}

@media (max-width: 1000px) {
  .counseling-card-wrapper {
    .content {
      flex-direction: column;
      align-items: center;
      .right-wrapper {
        width: 100%;
      }
    }
  }
}

@media (max-width: 575px) {
  .counseling-card-wrapper {
    padding: .75rem;
    .slots-wrapper {
      flex-direction: column;
      .slots-container {
        gap: 5px;
        flex-wrap: wrap;
        flex: 1 1;
        .slots{
          width: 87px;
        }
      }
    }
  }
}
