@use '../../../../config/styles/mixins.scss';

.sos-layout {
  width: 100vw;
  background-color: #fcfbf8;
  padding: 20px;
  height: 100vh;
  overflow-y: scroll;
  .sos-header {
    @include mixins.flex-row(flex-start, center);
  }

  .back-button {
    @include mixins.flex-row(center, flex-start);
    height: 40px;
    width: 40px;
    cursor: pointer;
  }
}

@media(max-width: 768px) {
  .sos-layout{
    padding: 10px;
  }
}
