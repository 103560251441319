.no-helpline-wrapper {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .no-helpline-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .decription-wrapper {
    color: rgba(26, 38, 49, 0.5);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    u {
      color: #263e58cc;
    }
  }
}
