@use '../../../../../config/styles/defaultStyle.scss' as default;
.chat-header {
  box-sizing: border-box;
  width: 100%;
  min-height: 75px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;

  .on-leave-chat-banner {
    background-color: #f5f3e4;
    width: 100%;
    text-align: center;
    padding: 0.75rem 0;
    font-size: 0.75em;
    font-weight: 500;
  }

  .chat-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    min-height: 75px;
    height: 75px;
    border-bottom: 1px solid default.$divider-color;
  }

  .user-info-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    &.hideUserInfo {
      visibility: hidden;
    }

    .image-profile-container {
      height: 44px;
      width: 44px;
      border-radius: 50%;
      border: 2px solid default.$primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.4rem;

      .user-tags-and-name {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
      }

      .user-type {
        height: 18px;
        width: auto;
        padding: 1px 9px;
        border-radius: 4px;
        background-color: #f1f1f5;
      }
    }
  }

  .toolbar-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .tool-item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      padding: 6px;
    }
  }
}
