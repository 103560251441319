@use '../../../config/styles/defaultStyle.scss' as default;

.popup-container {
  height: max-content;
  width: max-content;
  overflow: auto;
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: default.$main-content-background-color;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .close-icon {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    z-index: 12;
  }
}

/* media queries */
@media (max-width: 1000px) {
  .popup-container {
    width: 90%;
    top: 50%;
    left: 50%;
    .close-icon {
      top: 20px;
      right: 20px;
    }
  }
}
