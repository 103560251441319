.cancellation-wrappper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    min-width: 310px;
    width: 100%;
    .cancellation-body {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding-bottom: 24px;
    }
    .other-reason-input {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.20) !important;
      font-size: 14px;
      font-weight: 400;
      height: 100px;
      font-family: 'Poppins';
      resize: none;
      &:focus{
        outline: none;
      }
    }
  }
  