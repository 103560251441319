.banner-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 12px;
  border-radius: 8px;
  &.info {
    border: 1px solid #feddad;
    background: #fff7eb;
    width: -webkit-fill-available;
    a{
      color: #388BF2;
    }
  }
  &.error {
    border: 1px solid #f7abb2;
    background: #f4b4bb;
  }
  .alert-icon {
    padding-top: 4px;
    filter: brightness(0) saturate(100%) invert(16%) sepia(44%) saturate(727%)
      hue-rotate(357deg) brightness(98%) contrast(97%);
  }
}
