@use '@styles/mixins.scss';

.kebab-menu-container {
  position: relative;
  width: max-content;
  height: max-content;

  .menu-trigger {
    @include mixins.flex-col(center, center);
    @include mixins.selectable;
    width: 1rem;
    height: max-content;
    gap: 4px;
    &.horizontal {
      transform: rotate(90deg);
    }
    &.vertical {
      transform: rotate(0deg);
    }

    .menu-icon {
      @include mixins.flex-col;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}
