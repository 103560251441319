.floating-widget-container {
    position: fixed;
    height: max-content;
    width: max-content;
    z-index: 11;

    @media(max-width: 768px) {
        width: 92%;
    }
}
