span.typography {
    display: block;
    word-break: break-word;    

    &.primary {
        color: #101828;
    }

    &.secondary {
        color: #667085;
    }

    &.error {
        color: #E8324A;
    }

    &.solarized {
        color: #3C566F;
    }

    &.light {
        color: #FCFBF8;
    }

    &.disabled {
        color: rgba(26, 38, 49, 0.25);
    }
    &.trout {
        color: #484F54;
    }
    &.blackRiverFall{
        color: #344054;
    }
    &.swamp{
        color: rgba(26, 38, 49, 0.70);
    }
    &.squidInk{
        color: 	#1F384C;
    }
}