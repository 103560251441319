$page-margin: 5vw;

.layout-container {
  // min-height: 100vh;
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;

  .main-content {
    width: 90%;
    height: calc(100vh - 80px);
    margin: auto;
    box-sizing: border-box;

    &.no-page-margin {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media(max-width: 425px){
  .layout-container {
    .main-content{
      width: 100%;
      padding: 5px;
      margin: 0;
    }
  }
}
