@use '../../../config/styles/defaultStyle.scss' as default;

.button-container {
  min-height: 32px;
  min-width: 130px;
  border-radius: 100px;
  padding: 0px 15px;
  text-align: center;
  font-family: Poppins;
  cursor: pointer;

  &.primary {
    background-color: #263e58;
    border: none;
  }

  &.secondary {
    background-color: #fff;
    border: 1px solid default.$primary-color;
  }

  &.disabled {
    background-color: rgba(38, 62, 88, 0.5);
    border: none;
  }

  &.raised {
    min-height: 48px;
    min-width: 100px;
    border-radius: 8px;
    background-color: #fbfdff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border: 1px solid #e6edf2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img.icon {
      height: 15px;
      width: 15px;
      object-fit: contain;
      margin-right: 10px;
    }
  }

  &.caution {
    border: 1px solid default.$error-color;
    background-color: #fff;

    span {
      color: default.$error-color;
    }
  }

  &.blue {
    background-color: #263e58;
    border: none;
  }

  &.transparent {
    background-color: transparent;
    border: 1px solid default.$primary-color;
  }

  &.label {
    background-color: transparent;
    border: none;
  }
}
