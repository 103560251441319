.carousel-wrapper {
  width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  .carousel-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .carousel-controls{
      display: flex;
      gap: 24px;
    }
  }
  .control-item {
    width: 48px;
    height: 48px;
    margin: auto 0;
    border: none;
    cursor: pointer;
    padding: 0;
    background: none;
    .icons {
      width: 48px;
      height: 48px;
      margin: auto;
      margin: auto;
      color: #fdfeffb3;
      background: #0A0C0EB2;
      border-radius: 50%;

      &.disabledIcon {
        color:  #d0d4d9b3;
        background: #fff;
        cursor: not-allowed;
        
      }
    }
  }
  .carousel-item {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 25px;
  }
}
