.success-modal-wrapper {
  text-align: center;
  padding: 24px;
  .success-modal-content {
    min-width: 310px;
    width: 40vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    .illustration {
      width: 221px;
      height: 178px;
    }
    .success-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 1rem 2rem;
    }
  }
}
@media(max-width: 618px){
    .success-modal-wrapper {
        width: 100%;
        padding: 24px 0;
        .success-modal-content {
            width: auto;
            .success-modal-body {
                width: 80%;
            }
        }
    }
}
