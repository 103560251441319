@use '../../../../../config/styles/defaultStyle.scss' as default;

.chat-window-container {
  height: 525px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: default.$main-content-background-color;
  border-radius: default.$main-content-border-radius;
  -webkit-box-shadow: 5px 1px 67px -3px rgba(0, 0, 0, 0.56);
  -moz-box-shadow: 5px 1px 67px -3px rgba(0, 0, 0, 0.56);
  box-shadow: 5px 1px 67px -3px rgba(0, 0, 0, 0.56);

  &.full-view {
    width: 804px;
    @media(max-width: 768px) {
      width: 100%;
    }
  }

  &.chat-view {
    width: calc(100vw - 2rem);
    max-width: 500px;
  }

  .contacts-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 40%;
    border-right: 1px solid default.$divider-color;

    @media(max-width: 768px) {
      display: none;
    }

    .contacts-header-container {
      border-bottom: 1px solid #e9eaeb;
      height: 34px;
      padding: 20px;
      width: 100%;
    }

    .chats-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .chats-list {
      flex-grow: 1;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .how-to-use-chat {
      background-color: #fafbfc;
      padding: 1rem;
      font-size: 0.75em;
      border-bottom-left-radius: default.$main-content-border-radius;

      .title-content {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;
        cursor: pointer;
        .title {
          color: #545353;
          font-weight: 600;
        }
      }

      .expanded-content {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 500ms;
        > ul {
          overflow: hidden;
          margin: 0;
          padding: 0 1.25rem;
          color: #858585;
          font-weight: 400;
          > li {
            line-height: 1.25rem;
          }
        }
        &.open {
          grid-template-rows: 1fr;
          padding: 0.5rem 0;
        }

        .local-helpline-link {
          color: #1d5398;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .chat-container {
    &.full-view {
      width: 60%;
      height: inherit;

      @media(max-width: 768px) {
        width: 100%;
      }
    }

    &.chat-view {
      width: 100%;
      height: inherit;
    }
  }
}
