@use '../../../config/styles/mixins.scss';
.booking-management-wrapper {
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: auto;
  box-sizing: border-box;
  .header {
    @include mixins.flex-row(space-between, center);
    padding: 2rem 1rem 0.625rem;
  }
  .booking-list-wrapper {
    @include mixins.flex-col(center, normal);

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-sizing: border-box;
    padding: 1rem;
  }
}
