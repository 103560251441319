.onsite-upcoming-session-wrapper {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 58px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0rem 1.625rem;
    .right-content {
      cursor: pointer;
      transition: 0.3s ease;
      margin-right: 5px;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
