@use '../../../../../config/styles/defaultStyle.scss' as default;

.live-chat-container {
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .chat-messages-container {
    width: 100%;
    height: 440px;
    box-sizing: border-box;
    padding: 15px;
    overflow: auto;
  }
}
