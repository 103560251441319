@use '../../../config/styles/defaultStyle.scss' as default;

.context-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px;
  overflow-y: auto;
  height: max-content;
  width: max-content;
  min-height: fit-content;
  min-width: fit-content;
  position: absolute;
  border-radius: 16px;
  background-color: default.$main-content-background-color;
  visibility: hidden;
  z-index: 100;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  border: 1px solid #F2F4F7;
  &.show {
    visibility: visible;
  }

  .heading {
    padding: 0px 25px;
    margin-bottom: 20px;
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    height: max-content;
    width: max-content;
    width: 100%;

    .menu-item {
      width: 100%;
      height: 40px;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .selection-indicator {
        height: 90%;
        width: 4px;
        margin-right: 20px;
        background-color: transparent;

        &.selected {
          background-color: default.$primary-color;
        }
      }

      &.selected {
        background-color: rgba(230, 237, 242, 0.16);
      }

      .menu-item-icon{
        padding: 8px;
        height: 35px;
        width: 35px;
      }
    }
  }
}
