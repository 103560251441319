@use '../../../../config/styles/mixins.scss';
.booking-item-wrapper {
    box-sizing: border-box;
    @include mixins.flex-row(space-between, center);
    border: 1px solid #dde4f0;
    min-height: fit-content;
    padding: 1.5rem;
    border-radius: 8px;
    width: 100%;
    margin: auto;
    .booking-item {
      @include mixins.flex-col(center, flex-start);
      gap: 0.5rem;
      .booking-card-header {
        @include mixins.flex-row(center, center);
        gap: 0.5rem;
      }
      .provider-info {
        @include mixins.flex-row(center, center);
        gap: 0.5rem;
        .profile {
          height: 19px;
          width: 19px;
          border-radius: 50%;
        }
      }
    }
    .control-items{
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

@media(max-width: 767px){
  .booking-item-wrapper{
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    padding: .5rem;
    .booking-item {
      .booking-card-header {
        @include mixins.flex-col(flex-start, flex-start);
      }
    }
    .control-items{
      width: 100%;
      .button-container.secondary{
        width: 100%;
      }
    }
  }
}

@media(max-width: 450px){
  .booking-item-wrapper{
    .booking-item {
      .unordered-list {
        ul{
          display: flex;
          flex-direction: column;
          height: auto;
          gap: 10px;
          
          li{
            justify-content: flex-start;
            &::before{
              display: none;
            }
          }
        }
      }
    }
  }
}