
.floating-chat-button {
  position: fixed;
  bottom: 5rem;
  right: 2.5rem;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  background-color: #1a2631;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 22px 22px 90px 3px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 22px 22px 90px 3px rgba(0, 0, 0, 0.32);
  box-shadow: 22px 22px 90px 3px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  z-index: 8;

  .floating-contents-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .message-dots {
      position: absolute;
      top: 40%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4px;     

      .message-dot {
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: #1a2631;

      }
    }

    .unread-messages-count {
      position: absolute;
      top: -0.5rem;
      right: -0.3rem;
      background-color: #FF0000;
      height: 21px;
      width: 21px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 2px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}
