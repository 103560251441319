.onsite-schedule-modal {
  padding: 20px 16px;
  width: auto;
  .onsite-schedule-wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 300px;
    .onsite-location-timing-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .onsite-location-timing {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .customized-icon {
          filter: brightness(0) saturate(100%) invert(38%) sepia(13%)
            saturate(651%) hue-rotate(197deg) brightness(93%) contrast(90%);
        }
      }
    }
    .form-wrapper {
      width: 100%;
      form {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;
        .form-content {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .form-slots-wrapper
          {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .form-slots-date-wrapper
            {
              display: flex;
              flex-direction: column;
              gap: 4px;
            }
          }
          .form-label {
            font-size: 14px;
            font-weight: 500;
            color: #1a2631;
          }
          .onsite-phone-input {
            width: 100%;
            height: 45px;
            border-radius: 8px;
            border: 1px solid var(--Gray-300, #d0d5dd);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            padding: 10px 16px;
            &:disabled {
              background: #d0d5dd3b;
            }
            &+.flag-dropdown{
              display: none;
            }
          }
        }
        .btn-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .submit-cta {
            width: 100%;
            height: 48px;
            background-color: #263e58;
            font-family: Poppins, sans-serif;
            font-weight: 600;
            font-size: 16px;
            color: white;
            border-radius: 100px;
            border: none;
            cursor: pointer !important;
            &:disabled {
              background-color: rgba(38, 62, 88, 0.5);
              cursor: default;
            }
            transition: all 0.2s ease;
            &:hover {
              transform: scale(1.02);
            }
          }
          .cancel-cta {
            width: 100%;
            height: 48px;
            font-family: Poppins, sans-serif;
            font-weight: 600;
            border: none;
            font-size: 16px;
            cursor: pointer;
            text-align: center;
            color: #1a2631;
            border-radius: 100px;
            transition: all 0.2s ease;
            &:hover {
              transform: scale(1.02);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .onsite-schedule-modal {
    width: 98%;
    padding: 24px 5px;
    .onsite-schedule-wrapper {
      width: auto;
    }
  }
}
