.unordered-list {
  height: max-content;
  width: max-content;

  ul {
    list-style: none;
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: inline-flex;
    text-align: center;
    list-style-position: inside;
    height: 20px;

    li {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;

      img {
        margin-right: 0.45rem;
        filter: brightness(0) saturate(100%) invert(44%) sepia(10%)
          saturate(378%) hue-rotate(166deg) brightness(91%) contrast(87%);
      }

      &:not(:first-child)::before {
        font-size: 32px;
        content: '\2022';
        color: rgba(99, 108, 115, 0.24);
        font-weight: bold;
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }
}
