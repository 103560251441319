@use '../../../config/styles/mixins';
.add-leave-popup,
.reschedule-popup {
  width: 35vw;

  .popup-header {
    margin-bottom: 1rem;
  }

  .schedule-form,
  .reschedule-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 2rem;

    .form-item {
      @include mixins.flex-col;
      gap: 0.5rem;
      width: 100%;

      &.fixed-container {
        height: 200px;
        overflow-y: auto;
      }

      .form-item-row {
        @include mixins.flex-row(space-between, center);
        gap: 1rem;
        width: 100%;

        .form-item-row-item {
          @include mixins.flex-col;
          gap: 0.5rem;
          width: 100%;
        }
      }
    }

    .slot-select {
      width: 100%;
      margin-top: 2rem;
    }

    .action-container {
      width: 100%;
    }
  }
}
.reschedule-popup {
  .reschedule-form {
    .select-list-container {
      padding: 0.5rem;
    }
    .form-item {
     &.fixed-container {
      min-height: 70px;
      max-height: 150px;
      overflow-y: auto;
    }
    }
  } 
}

.on-leave-banner {
  background-color: #f5f3e4;
  width: 100%;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 0.5rem;
  font-size: 0.75em;
  font-weight: 500;
  margin-top: 1.5rem;
}

/* media queries */

@media (max-width: 1000px) {
  .add-leave-popup, .reschedule-popup {
    width: 100%;
    .schedule-form {
      .form-item {
        .form-item-row {
          @include mixins.flex-col(space-between, center);
        }
      }
    }
  }
}
