@use '@styles/defaultStyle.scss' as default;
.card-container {
  min-width: 180px;
  border-radius: 8px;
  padding: 1rem;
  text-align: left;
  font-family: Poppins;
  height: fit-content;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;
  .react-tooltip {
    padding: 11px 16px;
    border-radius: 8px;
    font-size: 12px;
    width: 315px;
    z-index: 5;
  }
  &.selected {
    background-color: default.$selected-card-background-color;
    border: 1px solid default.$selected-card-border-color;
  }

  &.default {
    background-color: default.$main-content-background-color;
    border: 1px solid default.$default-card-border-color;
  }
}
