.no-data-wrapper {
    width: 100%;
    margin: 5rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 2rem;
    box-sizing: border-box;
  .illustration-container {
    width: 100%;
    text-align: center;
    img{
        width: 250px;
        height: 190px;
    }
  }
  .no-data-body {
    width: 423px;
    text-align: center;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .no-data-footer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media(max-width: 768px){
    .no-data-wrapper {
        .no-data-body {
            width: 300px;
            padding: 5px;
        }
    }
}
