.cancellation-modal-wrapper{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-start;
    min-width: 500px;
    width: 37vw;
    .no-data-wrapper{
        margin: 0;
        padding: 0;
        img{
            width: 140px;
            height: 120px;
        }
    }
}